#modal-setting
  max-width: 1000px !important

.select-channel-setting
  width: 300px

.tab-content
  .active
    color: #333 !important
.add-group
  cursor: pointer
.config-item
  margin-bottom: 15px
  .ant-space
    width: 100%
    align-items: baseline
    .ant-space-item
      textarea
        width: 600px
      &:first-child
        min-width: 200px
        width: 200px
    .ant-select
      min-width: 300px