.messages-wrapper
  .message.attach-previous
    padding-top: 0
  .message.attach-next
    padding-bottom: 3px
  .name_user_left
    padding: 8px 16px
    text-transform: capitalize
    font-size: 15px
  .name_user_right
    padding: 8px 16px
    text-align: right
    font-size: 15px
  .spacer
    width: 50px
    height: 40px
  .btn-download-img
    z-index: 1 !important
  .message
    > .picture
      margin-bottom: -20px
      cursor: pointer
    > .spacer
      margin-bottom: -20px
  .message.left
    a
      color: #0d6efd !important
  .chat .message .content .bubble.attach-previous.left
    border-top-left-radius: 0
  .chat .message .content .bubble.attach-previous.right
    border-top-right-radius: 0
  .chat .message .content .bubble.attach-next.left
    border-bottom-left-radius: 0
  .chat .message .content .bubble.attach-next.right
    border-bottom-right-radius: 0
  .bubble.right a
    //color: #252423
  .bubble.left a
    color: #252423
    text-decoration: none

  .content-download
    display: flex
    align-items: center
    justify-content: space-around
    .icon-file
      margin-right: 10px
  .content-name
    font-weight: bold
    font-size: 15px
  .content-size
    font-size: 12px
  .content-icon
    min-width: 27px
    width: 27px
    font-size: 18px
    margin-left: 10px

.file-attach
  text-decoration: none
  &:hover
    text-decoration: none

.chat-client
  .message-content
    font-size: 15px
  .name_user_left
    font-size: 15px
  .name_user_right
    font-size: 15px
  .spacer
    width: 40px
    height: 40px

.voice-in-message
  width: 200px
  height: 30px
  
.message-ads
  position: absolute
  left: -32px
  bottom: 3px
  padding: 0 4px
  border: 1px solid green
  border-radius: 5px
  color: green
  background: #a0eea0
  font-size: 10px
  font-weight: 600

.audio-output-text
  margin-top: 10px