.sound-wave-container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px 15px
  background-color: #f0faff
  border-radius: 30px
  margin-bottom: 30px
  width: 85%

.audio-duration 
  margin-left: 10px
  font-size: 14px
  color: #333
.sound-wave
  height: 20px 
  display: flex 
  align-items: center 
  justify-content: center 
.bar 
  animation-name: wave-lg 
  animation-iteration-count: infinite 
  animation-timing-function: ease-in-out 
  animation-direction: alternate 
  background: #007bff 
  margin: 0 1.5px 
  height: 5px 
  width: 3px 
  &:nth-child(-n + 7),
  &:nth-last-child(-n + 7)
    animation-name: wave-md 
  &:nth-child(-n + 3),
  &:nth-last-child(-n + 3) 
    animation-name: wave-sm 

@keyframes wave-sm 
  0%
    opacity: 0.35 
    height: 5px 
@keyframes wave-md 
  0%
    opacity: 0.35 
    height: 7px 
@keyframes wave-lg
  0% 
    opacity: 0.35 
    height: 7px 
  100% 
    opacity: 1 
    height: 12px 
    opacity: 1 
    height: 20px 
    opacity: 1 
    height: 25px 