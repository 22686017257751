$panel-background: #fff
$nav-background: #fff

.messages-wrapper
  max-width: 100%
  flex-grow: 1
  height: 100%
  display: flex
  justify-content: center
  .messages-container
    flex-grow: 1
    max-width: 100%
    display: block
    padding-top: 10px
    .message-item
      padding: 0 25px
      &:last-child
        padding-bottom: 20px
      .message
        .message-session
          position: relative
          text-align: center
          padding: 10px 0
          &.session-start 
            &::before
              content: ""
              position: absolute
              bottom: 19px
              left: 0
              height: 1px
              width: 100%
              background: #eaeaea
          .ant-tag
            border-radius: 15px
            user-select: none
          .ant-tag-default
            color: #818181
        .message-main
          flex-grow: 1
          display: flex
          flex-direction: row
          .message-content
            p
              margin-bottom: 5px
            h1
              font-size: 16px
            h2
              font-size: 15px
            h3
              font-size: 14px
            ul, ol
              padding-left: 20px
          .oa_template
            padding: 8px 0 !important
          .picture img
            width: 60px
            height: 60px
            border-radius: 30px

          .picture .img
            width: 50px
            min-width: 50px
            height: 50px
            border-radius: 30px
            display: flex
            justify-content: center
            align-items: center
            background: #0167ac
            color: #f7f7f8
            font-size: 20px

          .content-x
            display: flex
            flex-direction: column
            min-width: 300px
            max-width: 80%
            cursor: pointer
            &.right
              align-items: end
            &.sticker
              min-width: unset !important

            .emoji-bubble
              position: relative
              margin: -2px 14px 0 14px
              font-size: 36px

            .emoji-bubble.right, .bubble-image.right
              text-align: right

            .bubble-image
              position: relative
              margin: 10px
              font-size: 14px
              cursor: pointer
              border-radius: 10px !important
              //overflow: hidden
              width: 270px
              height: 270px
              min-height: 270px
              border: 1px solid #e3e7e7

              img
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: 5px

            .bubble
              background: #ffffff
              color: rgb(37, 36, 35)
              margin: 0 14px
              padding: 8px 16px
              border-radius: 10px
              position: relative
              word-break: break-word
              width: fit-content
              box-shadow: 0 0 15px 2px rgba(0,0,0,.02)
            .bubble.right
              background: rgb(232, 235, 250)
              color: rgb(36, 36, 36)

            .bubble-right:before
              content: ""
              width: 0
              height: 0
              position: absolute
              border-left: 10px solid #e8ebfa
              border-right: 15px solid transparent
              border-top: 26px solid #e8ebfa
              border-bottom: 15px solid transparent
              border-radius: 0 5px 0 0
              right: -10px
              top: 0

            .bubble-left:before
              content: ""
              width: 0
              height: 0
              position: absolute
              border-left: 15px solid transparent
              border-right: 10px solid #ffffff
              border-top: 26px solid #ffffff
              border-bottom: 15px solid transparent
              border-radius: 5px 0 0 0
              left: -10px
              top: 0

            .message-slider.bubble-left:before
              border-left: unset
              border-right: unset

            .bubble-image:before
              border: 0 !important

            .message-details
              color: #9fadb8
              font-size: 11px
              background: transparent
              border: 0
              margin: 0 14px
              padding: 5px

            .message-details.right
              text-align: right

      .message.right
        .message-main
          flex-direction: row-reverse
          justify-content: flex-start

.messages-wrapper.has-pipeline .messages-container > div:last-child .message-details
  margin-bottom: 80px

div#wave
  position: relative
  text-align: center
  margin-left: auto
  margin-right: auto
  .dot
    display: inline-block
    width: 5px
    height: 5px
    border-radius: 50%
    margin-right: 3px
    background: #0167ac
    animation: wave 1.3s linear infinite

    &:nth-child(2)
      animation-delay: -1.1s

    &:nth-child(3)
      animation-delay: -0.9s

.pipeline-wrap
  position: absolute
  top: 54px
  left: 0
  width: 100%
  padding: 5px 10px 7px 10px
  background: #fff
  overflow: auto
  z-index: 99
  border-bottom: 1px solid #e0e0e0
  .pipeline-title
    position: relative
    .pipeline-title-left
      cursor: pointer
      width: fit-content
  .pipeline-slide
    .slick-slide
      .disable
        cursor: default
      .color-active
        background-color: #31cb31
        border-color: #31cb31
        span
          font-weight: 800
      .color-deactive
        border-color: #c5e8ff
        color:#004e82 !important
        background-color:#c5e8ff !important
      // .color-pass
      //   border-color: #31cb31
      //   background-color:#31cb31 !important
      .blur
        opacity: 0.6
      .btn-pipeline
        color: #fff
        white-space: nowrap
      .circle
        width: 8px
        height: 8px
        border-radius: 50%
        background: #fff
        display: inline-block
        margin: 0 5px
.tags-wrapper
  width: 100%
  position: relative
  left: 0
  z-index: 9
  .icon
    position: absolute
    top: 7px
    right: 15px
    font-size: 20px
    color: #0067ac
    cursor: pointer
  .lists
    display: flex
    flex-wrap: wrap
    background: #fff
    width: 100%
    padding: 7px
    .user-label-tag
      position: relative
      font-size: 11px
      padding: 2px 5px
      background: #33cc00
      color: #fff
      font-weight: 600
      text-align: center
      width: fit-content
      border-radius: 3px
      margin: 4px 5px 4px 0

.chat-alert
  display: flex
  width: 100%
  position: relative
  left: 0
  z-index: 9
  padding: 12px 20px
  background: #faeaea
  span
    margin-left: 10px
    font-size: 14px

.mention-name
  color: #0067ac
  cursor: pointer

.chat-danger
  display: flex
  width: 100%
  position: relative
  left: 0
  z-index: 9
  padding: 12px 20px
  background: #fff1d9
  span
    margin-left: 10px
    font-size: 14px

.chat-client  
  .messages-wrapper 
    .messages-container
      padding: 10px
    .message-item
      padding: 0 !important
      .message 
        .message-main
          .picture 
            .img
              width: 35px
              min-width: 35px
              height: 35px
            img.img
              background: transparent !important
  .messages-wrapper .message .content-x .bubble-image
    margin: 10px
    width: 200px
    height: 200px
    min-height: 200px

@keyframes wave
  0%, 60%, 100%
    transform: initial
  30%
    transform: translateY(-4px)


@media screen and (max-width: 699px)
  .messages-wrapper .message
    padding: 5px
