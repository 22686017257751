.select-wrap
  position: relative
  padding: 6px 10px
  .select-channel
    width: 90%
    max-width: 90%
    .ant-select
      width: 100%
  .select-status
    width: 100%
    max-width: 30%
.add-group
  width: 35px
  margin-top: 5px
.selection-tag
  position: relative
  cursor: pointer
  .bg-default
    margin-right: 10px
  .selection-tag-icon
    position: relative
    cursor: pointer
    margin-top: 5px
    .icon-check
      position: absolute
      bottom: 9px
      right: -5px
      color: #d00000
      font-size: 16px
    .filtered-count
      position: absolute
      right: -5px
      top: -6px
      background: red
      width: 16px
      height: 16px
      border-radius: 50%
      color: #fff
      font-size: 11px
      display: flex
      justify-content: center
      align-items: center
      font-weight: 600

.custom-channel-name
  display: flex
  justify-content: start
  .avatar-channel
    width: 26px
    min-width: 26px
    height: auto
    img
      width: 100%
      border-radius: 6px
  span
    margin-left: 10px
    font-weight: 600
    font-size: 14px

.select-channel .ant-select-selector
  border: 0 !important