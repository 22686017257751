#modal-setting
  max-width: 1000px !important

.select-channel-setting
  width: 300px

.group-quota-list
  margin-top: 20px

.avatar-user
  width: 40px
  min-width: 40px
  height: 40px
  border-radius: 50%
  margin-right: 5px

.avatar-user-text
  display: flex
  width: 40px
  height: 40px
  border-radius: 20px
  justify-content: center
  align-items: center
  background: #0167ac
  color: #f7f7f8
  font-size: 18px
  margin-right: 5px
  text-transform: uppercase

.select-users-add-group
  .ant-tag
    .ant-tag-close-icon
      color: rgba(0, 0, 0, 0.45) !important