$primary-color: #007bff
$background-color: #f0faff
$wave-width: 3px
$wave-spacing: 3px
$wave-radius: 2px

.voice-recording-wrapper
  .ant-drawer-body
    display: flex
    flex-direction: column
    align-items: center
  .audio-record
    display: flex
    justify-content: center
    align-items: center
    .btn-record
      display: flex
      justify-content: center
      align-items: center
      width: 60px
      height: 60px
      border-radius: 50%
      background: #007bff
      cursor: pointer
      &.default
        background: #c6c6c6
    .before-record
      display: flex
      flex-direction: column
      align-items: center
    .after-record
      display: flex
      &__item
        text-align: center

  .recording
    position: relative
    border: none
    border-radius: 50%
    cursor: pointer
    outline: none
    display: flex
    align-items: center
    justify-content: center
    transition: transform 0.2s

    &:hover 
      transform: scale(1.05)
    

    &:active 
      transform: scale(0.95)

    &.recording
      animation: pulse 1s infinite

  @keyframes pulse
    0%
      box-shadow: 0 0 0 0 rgba(0, 163, 255, 0.7)
    70%
      box-shadow: 0 0 0 20px rgba(255, 0, 0, 0)
    100% 
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0)


  .ant-drawer-body
    text-align: center